/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f9fafb;
    display: flex;
    flex-direction: column;
    min-height: auto;
  }
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  
  main {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  html, body {
    height: 100%;
  }
  
  /* Additional utility class for flex column layout */
  .flex-col-layout {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  
    
/* @import "swiper/swiper-bundle.min.css";
 */
